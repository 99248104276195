<template>
  <v-dialog v-model="dialog" width="750" persistent :fullscreen="isTouchScreen">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="msaBlue white--text"
        v-bind="attrs"
        v-on="on"
        @click="initSketchPad()"
      >
        Add Image
      </v-btn>
    </template>
    <v-card :tile="isTouchScreen">
      <v-card-title class="msaBlue white--text"> Image </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" align="right">
            <v-btn @click="$refs.uploader.click()" class="msaBlue white--text">
              Add Photo
            </v-btn>
          </v-col>
          <!-- the style is to prevent signature missing when scroll on a mobile device -->
          <v-col cols="12" :style="{ 'touch-action': 'none' }">
            <canvas
              ref="canvas"
              :style="{ width: '100%', height: '400px' }"
            ></canvas>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="clear()" color="red" outlined>clear</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="close()" text color="red">cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          :disabled="disableSave"
          @click="confirm()"
        >
          confirm
        </v-btn>
      </v-card-actions>

      <input
        type="file"
        ref="uploader"
        class="d-none"
        accept=".jpg, .jpeg, .png"
        @change="addImage($event)"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import SignaturePad from 'signature_pad';
export default {
  name: 'AddImage',
  computed: {
    disableSave() {
      return (this.canvas == null || this.canvas.isEmpty()) && !this.imageAdded;
    },
  },
  data() {
    return {
      dialog: false,
      canvas: null,
      menu: false,
      imageAdded: false,
      isTouchScreen: false,
    };
  },
  methods: {
    clear() {
      this.canvas.clear();
      this.imageAdded = false;
    },
    close() {
      this.menu = false;
      this.canvas = null;
      this.dialog = false;
    },
    async addImage(e) {
      this.clear();
      if (!e.target.files.length) {
        return;
      }
      let file = e.target.files[0];

      this.$root.toggleLoaderText(true);
      try {
        file = await this.$helpers.processFile(
          file,
          this.$constants.ACCEPTED_MIME_TYPES.IMAGE,
          false,
        );
      } catch (error) {
        this.$root.toggleLoaderText(false);
        this.clear();
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
        return;
      }

      // resize image to fit the canvas
      file = await this.$helpers.resizeImage(file, 700);
      this.$root.toggleLoaderText(false);

      const base_image = new Image();
      const reader = new FileReader();
      const context = this.$refs.canvas.getContext('2d');

      reader.addEventListener(
        'load',
        () => {
          // convert image file to base64 string
          base_image.src = reader.result;
          base_image.onload = () => {
            context.drawImage(base_image, 0, 0);
            this.imageAdded = true;
          };
        },
        false,
      );

      reader.readAsDataURL(file);
    },
    // resize canvas for different screen sizes
    // so the typed and signed signature will display properly
    resizeCanvas() {
      if (this.dialog) {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);

        this.$refs.canvas.width = this.$refs.canvas.offsetWidth * ratio;
        this.$refs.canvas.height = this.$refs.canvas.offsetHeight * ratio;
        this.$refs.canvas.getContext('2d').scale(ratio, ratio);
        this.canvas.clear(); // otherwise isEmpty() might return incorrect value
      }
    },
    initSketchPad() {
      if (this.canvas == null) {
        // Need to wait for DOM load
        setTimeout(() => {
          this.canvas = new SignaturePad(this.$refs.canvas);
          window.onresize = this.resizeCanvas;
          this.resizeCanvas();
        }, 100);
      }
    },
    async confirm() {
      const dataURL = this.canvas.toDataURL();
      const guid = await this.createGuid();
      let blob = this.dataURLToBlob(dataURL);
      this.$emit('addImage', {
        guid: guid,
        binary: blob,
        type: this.$constants.BINARY_TYPES.IMAGE,
        fileType: blob.type,
        dataURL: dataURL,
      });
      this.close();
    },
    dataURLToBlob(dataURL) {
      // Code taken from https://github.com/ebidel/filer.js
      var parts = dataURL.split(';base64,');
      var contentType = parts[0].split(':')[1];
      var raw = window.atob(parts[1]);
      var rawLength = raw.length;
      var uInt8Array = new Uint8Array(rawLength);

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
    async createGuid() {
      const url = 'create-guid?format=json';
      return this.$axios
        .post(url, { loaderText: 'Loading...' })
        .then((response) => response.data);
    },
  },
  mounted() {
    // set dialog to fullscreen if it's on a touch screen
    if ('ontouchstart' in window || navigator.maxTouchPoints > 1) {
      this.isTouchScreen = true;
    }
  },
};
</script>

<style scoped>
canvas {
  border: 1px black solid;
  border-radius: 5px;
}
</style>
